<template>
    <div class="box">
        <h2>Prozess wählen</h2>
        <div class="body">
            <div class="form-group form-float form-select no-error">
                <select v-model="selProduct" id="selProduct" name="selProduct" placeholder=" ">
                    <option value=""></option>
                    <optgroup v-for="group in productGroups" v-bind:key="group.id" :label="group.label">
                        <option v-for="entry in group.products" v-bind:key="entry.id" v-bind:value="entry.id">
                            {{ entry.label }}
                        </option>
                    </optgroup>
                </select>
                <label for="selProduct">Produkt wählen</label>
            </div>
            <table>
                <tbody>
                    <tr class="clickable-row" v-for="process in product.processes" v-bind:key="process.id">
                        <td @click="load(process.id)">{{ process.label }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selProduct: "",
            productGroups: [],
            product: {
                id: "",
                label: "",
                processes: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        load(id) {
            this.$router.push({ name: "docs_process_edit", params: { id: id } });
        },
        fetchData() {
            this.fetchProductGroups();
        },
        fetchProductGroups() {
            this.$api.get("/docs/products").then(
                response => {
                    this.productGroups = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Produkte");
                }
            );
        },
        fetchProduct() {
            this.$api.get("/docs/product/" + this.selProduct).then(
                response => {
                    this.product = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Prozesse");
                }
            );
        }
    },
    watch: {
        selProduct: function() {
            this.fetchProduct();
        }
    }
};
</script>
